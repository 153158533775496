$boxshadow:
            grey -2px -4px 15px 2px,
            grey 1px 3px 15px 2px;

.MySlider{
   display: grid;
   grid-template-rows: repeat(6, fr);
   grid-template-columns: repeat(6, fr);
   height: 80vh; //view heigh
   overflow: hidden;
   box-shadow: $boxshadow;
   background: grey;
   .LeftArrow{
    height:80vh;
    background: rgba(22, 22, 22, 0);
    overflow: hidden;
    grid-column:1/3; 
    grid-row:1/7; 
    z-index: 3; 
    min-width:10vw;
    display: flex;
    align-items:center; 
    justify-content:center;
    transition: all 1s;
    &:hover{
        background: rgba(22, 22, 22, 0.7);
        transition: all 1s;
    }
    .LeftArrowIcon{
        &:hover{ }
    }
}

}
